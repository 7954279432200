<template>
  <div class="container">
    <ErrorHandler :data="error"/>
</div>
</template>
<script>
import { defineAsyncComponent } from 'vue';

const ErrorHandler = defineAsyncComponent(() => import('../components/Error.vue'));
export default {
  name: 'NotFound',
  components: {
    ErrorHandler,
  },
  data: () => ({
    error: {
      header: '404',
      link: '/',
      linkText: 'Return',
      message: 'Page not found',
    },
  }),
};
</script>
