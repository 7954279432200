<template>
<div :data-bs-theme="isDarktheme ? 'dark' : 'light'">
  <div class="container">
    <div v-if="serverState.isBackFault">
      <ErrorScreen :data="serverState"/>
    </div>
    <div v-else>
      <div class="container text-center py-5">
        <div class="row align-items-start">
          <div class="col">
          </div>
          <div class="col"></div>
          <div class="col">
            <div class="form-check form-switch">
              <label class="form-check-label" for="switcher">Dark theme
                <input class="form-check-input" type="checkbox"
                role="switch" id="switcher" v-model="isDarktheme"
                aria-checked="dark">
              </label>
            </div>
          </div>
        </div>
      </div>
        <Header
          :user="user"
          @fetchData="fetchData"
        />
        <Portfolio :data="portfolioList"
                   :hidequote="user.info.Fake"/>
        <Links  :data="cv"
                :hidequote="user.info.Fake"/>
        <Links  :data="sml"
                :hidequote="user.info.Fake"/>
        <Email  :emailAddr="user.info.Email"/>
        <Footer :buildInfo="buildInfo" />
    </div>
  </div>
</div>
</template>

<script>
import * as api from '@/api/api';
import { defineAsyncComponent } from 'vue';
import packageInfo from '../buildInfo.json';

const Header = defineAsyncComponent(() => import('../components/Header.vue'));
const Portfolio = defineAsyncComponent(() => import('../components/Portfolio.vue'));
const Links = defineAsyncComponent(() => import('../components/Links.vue'));
const Email = defineAsyncComponent(() => import('../components/Email.vue'));
const Footer = defineAsyncComponent(() => import('../components/Footer.vue'));
const ErrorScreen = defineAsyncComponent(() => import('../components/Error.vue'));

export default {
  name: 'HomePage',
  components: {
    Header,
    Portfolio,
    Links,
    Email,
    Footer,
    ErrorScreen,
  },
  data: () => ({
    id: 1,
    isDarktheme: true,
    serverState: {
      isBackFault: false,
      showSpinner: false,
      header: 'Oops!',
      link: '/',
      linkText: 'Reload page',
      message: 'We have noticed a problem with our server. Our team is already working on it, so please be patient. Thank you for your understanding!',
    },
    user: {
      info: {},
    },
    portfolioList: {
      portfolio: [],
      quote: {
        text: 'I Order The Food, You Cook The Food. The Customer Gets The Food. We Do That For 40 Years, And Then We Die.',
        author: 'Squidward Tentacles',
      },
    },
    cv: {
      links: [],
      header: 'My CV.',
      quote: {
        text: 'Fortunately, I Have Enough Talent For All Of You.',
        author: 'Squidward Tentacles',
      },
    },
    sml: {
      links: [],
      header: 'My Social Media Links.',
      anchor: 'msml',
      quote: {
        text: 'Patrick, go be stupid somewhere else!',
        author: 'Squidward Tentacles',
      },
    },
    buildInfo: {
      front: {
        id: packageInfo.id,
        url: packageInfo.url,
        branch: packageInfo.branch,
      },
      back: {
        id: '',
        url: '',
        branch: '',
        count: '',
      },
    },
  }),
  async beforeMount() {
    this.serverState.showSpinner = true;
    try {
      this.loadUserInfo();
      const date = await api.backInfo();
      this.buildInfo.back.id = `${date.data.Hash} (${date.data.Branch})`;
      this.buildInfo.back.url = date.data.BuildUrl;
      this.buildInfo.count = date.data.Accounts;
    } catch (e) {
      console.error(e);
      this.serverState.isBackFault = true;
    }
    this.serverState.showSpinner = false;
  },
  methods: {
    async loadUserInfo(id) {
      let date;
      this.id = (id !== undefined) ? id : 1;
      try {
        this.serverState.showSpinner = true;
        date = await api.getUser(this.id);
      } catch (e) {
        console.error(e);
        this.serverState.isBackFault = true;
      }
      this.serverState.showSpinner = false;
      this.user.info = date.data.Person;
      document.title = `${this.user.info.Name} ${this.user.info.Surname} - ${this.user.info.Jobtitle}`;
      try {
        this.portfolioList.portfolio = [];
        date.data.Projects.forEach((item) => {
          this.portfolioList.portfolio.push(item);
        });
      } catch (e) {
        console.debug(e);
      }
      if (date.data.Links) {
        this.cv.links = [];
        this.sml.links = [];
        date.data.Links.forEach((item) => {
          switch (item.Type) {
            case 'cv':
              this.cv.links.push(item);
              break;
            case 'socmedia':
              this.sml.links.push(item);
              break;
            default:
              console.log('unknown type of link');
          }
        });
      }
    },
    async fetchData() {
      try {
        const rand = Math.floor(Math.random() * (this.buildInfo.count)) + 1;
        if (this.id !== rand) {
          this.loadUserInfo(rand);
        } else {
          this.fetchData();
        }
      } catch (e) {
        console.error(e);
        this.serverState.isBackFault = true;
      }
    },
  },
};
</script>
